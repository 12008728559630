import React from 'react'
import { useSelector } from 'react-redux'
import { Link, Redirect, useParams } from 'react-router-dom'

import { Row, Col, Container, useScreenClass } from 'react-grid-system'
import Moment from 'react-moment'
import moment from 'moment'

import currency from 'helpers/currency'

import Box from 'styles/Box'
import { H3 } from 'styles/Heading'
import Text from 'styles/Text'
import Card from 'styles/Card'
import Button from 'styles/Button'
import { ArrowLeftIcon, ArrowRightIcon } from 'styles/Icon'

import { QuoteDataType } from 'types'

import { ApplicationState } from 'reducers'
import BookingSuccess from 'pages/BookingSuccess'

const NextButton: React.FC = () => {
  const { itineraryId }: any = useParams() as { itineraryId: string }
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)

  if (itinerary?.hidePrices || itinerary?.hideDates) {
    return null
  }

  return (
    <Link to={`/${itineraryId}/checkout`}>
      <Button type="primary" afterIcon={<ArrowRightIcon size="1rem" />} small>
        Reis boeken
      </Button>
    </Link>
  )
}

const Book: React.FC = () => {
  const { itineraryId }: any = useParams() as { itineraryId: string }
  const { itinerary }: QuoteDataType = useSelector((state: ApplicationState) => state.quote.data)
  const screenClass = useScreenClass()
  const inlineButtons = ['sm', 'md', 'lg', 'xl', 'xxl'].includes(screenClass)
  if (itinerary?.status === 'booked') {
    return <BookingSuccess />
  }

  if (!itinerary) {
    return null
  }

  const days = moment(itinerary.endDate).diff(itinerary.startDate, 'days') + 1

  return (
    <Container>
      <Box m={0} mt="4rem" mb="2rem">
        {itinerary.status === 'open' && !itinerary.isZRA && !itinerary.hideDates && !itinerary.hidePrices && (
          <p>
            U staat op het punt om deze reis te boeken. Heeft u nog vragen over deze offerte, neem dan gerust contact
            met ons op.
          </p>
        )}

        {itinerary.status === 'open' && (itinerary.isZRA || itinerary.hideDates || itinerary.hidePrices) && (
          <p>Neem contact op met uw reisadviseur om deze reis te boeken.</p>
        )}

        {itinerary.status === 'expired' && (
          <p>
            Deze offerte is verlopen. Wilt u deze {itinerary.isExcursion ? "extra's" : 'reis'} toch boeken? Dat is
            uiteraard mogelijk, we moeten alleen de beschikbaarheid en tarieven opnieuw voor u bekijken. Neem contact op
            met uw reisadviseur om de mogelijkheden te bespreken.
          </p>
        )}
      </Box>

      <H3>Samenvatting</H3>

      <Card>
        <Row>
          <Col sm={3}>
            <strong>Naam:</strong>
          </Col>
          <Col sm={9}>
            <span>{itinerary.name}</span>
          </Col>
        </Row>
        {!itinerary.hideDates && (
          <>
            <Row>
              <Col sm={3}>
                <strong>Vertrek:</strong>
              </Col>
              <Col sm={9}>
                <span>
                  <Moment format="D MMMM YYYY">{itinerary.startDate}</Moment>
                </span>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <strong>Terugkomst:</strong>
              </Col>
              <Col sm={9}>
                <span>
                  <Moment format="D MMMM YYYY">{itinerary.endDate}</Moment>
                </span>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col sm={3}>
            <strong>Reisduur:</strong>
          </Col>
          <Col sm={9}>
            <span>
              {days} dag{days !== 1 && 'en'}
            </span>
          </Col>
        </Row>
        <Box m={0} mt="1rem">
          <Row>
            <Col sm={3}>
              <strong>Aantal personen:</strong>
            </Col>
            <Col sm={9}>
              <span>{itinerary.travellers}</span>
            </Col>
          </Row>
          {!itinerary.hidePrices && (
            <>
              <Row>
                <Col sm={3}>
                  <strong>Prijs per persoon:</strong>
                </Col>
                <Col sm={9}>
                  <span>{currency.format(itinerary.totalCosts / (itinerary.travellers || 1))}</span>
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <strong>Totaalkosten:</strong>
                </Col>
                <Col sm={9}>
                  <strong>
                    <Text color="neutral">{currency.format(itinerary.totalCosts)}</Text>
                  </strong>
                </Col>
              </Row>
            </>
          )}
        </Box>
        {!itinerary.hidePrices && (
          <>
            <Box m={0} mt="1rem">
              {itinerary.deposit > 0 && itinerary.deposit !== itinerary.totalCosts && (
                <Row>
                  <Col sm={3}>
                    <strong>Aanbetaling:</strong>
                  </Col>
                  <Col sm={9}>
                    <Text size="lg" color="primary">
                      {currency.format(itinerary.deposit)}
                    </Text>
                  </Col>
                </Row>
              )}
            </Box>
          </>
        )}
      </Card>

      {itinerary?.status === 'open' && !itinerary.isZRA && (
        <Box m={0} mb="8rem">
          <Box m={0} mb="1rem" inline={inlineButtons}>
            <Box m={0} mb="1rem" mr="1rem">
              <Link to={`/${itineraryId}/summary`}>
                <Button type="neutral" variant="dark" small beforeIcon={<ArrowLeftIcon size="1rem" />}>
                  Samenvatting
                </Button>
              </Link>
            </Box>
            {!itinerary.isZRA && <NextButton></NextButton>}
          </Box>
        </Box>
      )}
    </Container>
  )
}

export default Book
